<template>
  <div>
    <v-card flat class="mt-5">
      <v-row no-gutters>
        <v-col
          v-if="$vuetify.breakpoint.name != 'xs'"
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xl="12"
        >
          <v-toolbar dense elevation="0">
            <v-select
              v-model="shiftName"
              label="Select Shift"
              dense
              :disabled="isLoading == true"
              outlined
              :items="ShiftItems"
              style="max-width: 220px"
              item-text="workshift_name"
              item-value="workshift_id"
              @change="get_daily_present_reports()"
              class="mt-6"
            ></v-select>
            <v-autocomplete
              class="mt-6 ml-2"
              v-model="locationFilter"
              :items="locationItems"
              :disabled="isLoading == true"
              label="Filter by Location"
              @change="get_daily_present_reports()"
              item-text="location_name"
              item-value="location_name"
              style="max-width: 220px"
              dense
              outlined
            ></v-autocomplete>
            <v-dialog
              ref="dialog"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-6 ml-2"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="Filter by Date"
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  style="max-width: 150px"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                  r
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="minmonth"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="#f4813f"
                  v-on:click="get_daily_present_reports()"
                  @click="$refs.dialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-text-field
              v-model="search"
              label="Search"
              dense
              append-icon="mdi-magnify"
              style="max-width: 220px"
              class="mt-6 ml-2"
            ></v-text-field>
            <v-spacer />
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click="view_geo_for_the_day()" v-on="on">
                  <v-btn small class="gradient-bg white--text mr-2">
                    <v-icon>mdi-map</v-icon>
                  </v-btn>
                </a>
              </template>
              <span class="white--text">View Geo Map for {{ date }}</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
        <v-col
          cols="12"
          md="4"
          v-if="$vuetify.breakpoint.name == 'xs'"
          class="mt-2"
        >
          <v-toolbar dense elevation="0">
            <v-autocomplete
              class="mt-5 mr-2"
              v-model="locationFilter"
              :items="locationItems"
              :disabled="isLoading == true"
              label="Filter by Location"
              item-text="location_name"
              item-value="employee_id"
              dense
              outlined
            ></v-autocomplete>
            <v-dialog
              ref="dialog"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-5 mr-2"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="Filter by Date"
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="minmonth"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="#f4813f"
                  v-on:click="get_daily_present_reports()"
                  @click="$refs.dialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="4" v-if="$vuetify.breakpoint.name == 'xs'">
          <v-toolbar dense elevation="0">
            <v-text-field
              v-model="search"
              label="Search"
              dense
              append-icon="mdi-magnify"
              class="mt-6 mr-2"
            ></v-text-field>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="1" v-if="$vuetify.breakpoint.name == 'xs'">
          <v-toolbar dense elevation="0">
            <v-spacer v-if="$vuetify.breakpoint.name != 'xs'"></v-spacer>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click="view_geo_for_the_day()" v-on="on">
                  <v-btn small class="gradient-bg white--text mr-2">
                    <v-icon>mdi-map</v-icon>
                  </v-btn>
                </a>
              </template>
              <span class="white--text">View Geo Map for {{ date }}</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :height="height"
          :headers="PresentData"
          :items="PresentItems"
          dense
          :search="search"
          :loading="isLoading"
          :items-per-page="100"
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text"
              >No prezence / swipe data available
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.first_swipe`]="{ item }">
            <span v-text="get_datefirstSwipe(item).split(',')[1]"></span>
          </template>
          <template v-slot:[`item.last_swipe`]="{ item }">
            <span v-text="get_datelastSwipe(item).split(',')[1]"></span>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="view_swipes_for_the_day(item)"
                  color="primary"
                  v-if="item"
                  small
                  v-on="on"
                  >mdi-eye</v-icon
                >
              </template>
              <span class="white--text">View all Swipes</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">{{ item.user_name }}</div>
              </template>
              <span class="white--text">{{ item.user_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.location_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">
                  {{ item.location_name }}
                </div>
              </template>
              <span class="white--text">{{ item.location_name }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
    <ViewMemberSwipes
      :userDetailsObj="userDetailsObj"
      :viewMemberSwipesDialog="viewMemberSwipesDialog"
      @close_view_member_details="viewMemberSwipesDialog = false"
      :toggle="toggle"
      :date="date"
    />
    <DailyPresentGeo
      :dailyPresentGeoDialog="dailyPresentGeoDialog"
      :swipeData="geoSwipData"
      ref="DailyPresentGeo"
      @close_dailyPresentGeoDialog="dailyPresentGeoDialog = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { GetDayReportDetails } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import ViewMemberSwipes from "@/components/Dialogs/ViewMemberSwipes.vue";
import DailyPresentGeo from "@/views/ChildHomePages/Reports/DailyReports/DailyPresentGeo.vue";
import { Getshiftwork } from "@/mixins/GetWorkshift.js";
import { GetLocation } from "@/mixins/GetLocationList.js";
const { find } = require("geo-tz");

export default {
  components: {
    Overlay,
    ViewMemberSwipes,
    DailyPresentGeo,
  },
  mixins: [GetLocation, Getshiftwork],
  data: (vm) => ({
    fixed: true,
    viewMemberSwipesDialog: false,
    dailyPresentGeoDialog: false,
    userDetailsObj: {},
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    search: "",
    toggle: 0,
    height: 0,
    locationFilterItems: [],
    geoSwipData: [],
    isLoading: false,
    locationFilter: "ALL",
    PresentData: [
      { text: "Member Id", value: "employee_id" },
      { text: "Member Name", value: "user_name" },
      { text: "Last Swipe Location", value: "location_name" },
      { text: "First Swipe", value: "first_swipe" },
      { text: "Last Swipe", value: "last_swipe" },
      { text: "#Swipes", value: "no_of_swipes" },
      { text: "Duration", value: "total_duration" },
      { text: "All Swipes", value: "Actions", sortable: false },
    ],
    PresentItems: [],
    filterlocation: [],
    locationItems: [],
    overlay: false,
    ShiftItems: [],
    array1: [],
    shiftName: "ALL",
    timeZone: "",
    minmonth: "",
    selectedDate: "",
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.locationFilter = "ALL";
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  async created() {
    this.overlay = true;
    this.isLoading = true;
    this.height = window.innerHeight - 210;
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(this.timeZone);
    // const latitude = 51.1111015;
    // const longitude = 4.1761369;

    // console.log(find(latitude, longitude)[0]);

    await this.Getshiftwork();
    await this.GetLocation();
    await this.fetch_deatils();
    await this.get_daily_present_reports();
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    fetch_deatils() {
      this.GetWorkshiftitems.forEach((element) => {
        this.ShiftItems.push({
          workshift_name: element.workshift_name,
          workshift_id: element.workshift_id,
        });
      });
      this.ShiftItems.unshift("ALL");
      this.GetLocationitems.forEach((element) => {
        this.locationItems.push({
          location_name: element.loc_name,
          location_id: element.loc_id,
        });
      });
      this.locationItems.unshift("ALL");
    },
    get_datefirstSwipe(date) {
      let a = new Date(date.first_swipe * 1000).toLocaleTimeString("en-US", {
        timeZone:
            date.time_zone == null
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : date.time_zone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    get_datelastSwipe(date) {
      let a = new Date(date.last_swipe * 1000).toLocaleTimeString("en-US", {
        timeZone:
            date.time_zone == null
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : date.time_zone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    // get_date(date) {
    //   let a = new Date(date * 1000).toLocaleTimeString("en-In", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   });
    //   return a.toUpperCase();
    // },
    view_geo_for_the_day() {
      this.dailyPresentGeoDialog = true;
      let rec = {
        date: Math.floor(new Date(this.date).getTime() / 1000.0),
        swipeData: this.geoSwipData,
      };
      this.$refs.DailyPresentGeo.getMethods(rec);
    },

    view_swipes_for_the_day(item) {
      this.toggle = 1;
      this.userDetailsObj = item;
      this.viewMemberSwipesDialog = true;
    },
    async get_daily_present_reports() {
      this.isLoading = true;
      this.overlay = true;
      try {
        this.PresentItems = [];
        this.filterlocation = [];
        await API.graphql(
          graphqlOperation(GetDayReportDetails, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              swipe_time_stamp: new Date(this.date).getTime() / 1000,
              workshift_id: this.shiftName,
            },
          })
        ).then((res) => {
          if (this.locationFilter == "ALL") {
            this.PresentItems = res.data.GetDayReportDetails.data;
            // console.log(this.PresentItems, "PresentItems");
          } else {
            // console.log(this.PresentItems, "PresentItems");
            this.PresentItems = res.data.GetDayReportDetails.data.filter(
              (val) => val.location_name == this.locationFilter
            );
          }
          let baseImage = "https://maps.google.com/mapfiles/ms/icons/";
          let markerImage = [
            "red-dot.png",
            "yellow-dot.png",
            "green-dot.png",
            "blue-dot.png",
            "orange-dot.png",
            "purple-dot.png",
            "pink-dot.png",
          ];
          for (let i = 0; i < this.PresentItems.length; i++) {
            for (let j = 0; j < this.PresentItems[i].swipeslat.length; j++) {
              this.geoSwipData.push({
                reource_id: this.PresentItems[i].user_id,
                member_name: this.PresentItems[i].user_name,
                swipe_location: this.PresentItems[i].location_name,
                position: {
                  lat: Number(this.PresentItems[i].swipeslat[j].user_lat),
                  lng: Number(this.PresentItems[i].swipeslat[j].user_long),
                },
                marker:
                  baseImage +
                  markerImage[Math.floor(Math.random() * markerImage.length)],
              });
            }
          }
        });
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },

    getcal() {
      this.modal = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
</style>
