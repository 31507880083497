var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.name != 'xs')?_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-select',{staticClass:"mt-6",staticStyle:{"max-width":"220px"},attrs:{"label":"Select Shift","dense":"","disabled":_vm.isLoading == true,"outlined":"","items":_vm.ShiftItems,"item-text":"workshift_name","item-value":"workshift_id"},on:{"change":function($event){return _vm.get_daily_present_reports()}},model:{value:(_vm.shiftName),callback:function ($$v) {_vm.shiftName=$$v},expression:"shiftName"}}),_c('v-autocomplete',{staticClass:"mt-6 ml-2",staticStyle:{"max-width":"220px"},attrs:{"items":_vm.locationItems,"disabled":_vm.isLoading == true,"label":"Filter by Location","item-text":"location_name","item-value":"location_name","dense":"","outlined":""},on:{"change":function($event){return _vm.get_daily_present_reports()}},model:{value:(_vm.locationFilter),callback:function ($$v) {_vm.locationFilter=$$v},expression:"locationFilter"}}),_c('v-dialog',{ref:"dialog",attrs:{"color":"#f4813f","return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-6 ml-2",staticStyle:{"max-width":"150px"},attrs:{"dense":"","outlined":"","persistent-hint":"","disabled":_vm.isLoading == true,"label":"Filter by Date","color":"#f4813f","append-icon":"mdi-calendar","r":""},on:{"click:append":function($event){_vm.date ? _vm.getcal() : _vm.getcal()}},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1736500653),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"min":_vm.minmonth,"max":new Date().toISOString().substr(0, 10),"color":"#f4813f","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":[function($event){return _vm.get_daily_present_reports()},function($event){return _vm.$refs.dialog.save(_vm.date)}]}},[_vm._v(" OK ")])],1)],1),_c('v-text-field',{staticClass:"mt-6 ml-2",staticStyle:{"max-width":"220px"},attrs:{"label":"Search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({on:{"click":function($event){return _vm.view_geo_for_the_day()}}},on),[_c('v-btn',{staticClass:"gradient-bg white--text mr-2",attrs:{"small":""}},[_c('v-icon',[_vm._v("mdi-map")])],1)],1)]}}],null,false,1181159147)},[_c('span',{staticClass:"white--text"},[_vm._v("View Geo Map for "+_vm._s(_vm.date))])])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.name == 'xs')?_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"4"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-autocomplete',{staticClass:"mt-5 mr-2",attrs:{"items":_vm.locationItems,"disabled":_vm.isLoading == true,"label":"Filter by Location","item-text":"location_name","item-value":"employee_id","dense":"","outlined":""},model:{value:(_vm.locationFilter),callback:function ($$v) {_vm.locationFilter=$$v},expression:"locationFilter"}}),_c('v-dialog',{ref:"dialog",attrs:{"color":"#f4813f","return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-5 mr-2",attrs:{"dense":"","outlined":"","persistent-hint":"","disabled":_vm.isLoading == true,"label":"Filter by Date","color":"#f4813f","append-icon":"mdi-calendar"},on:{"click:append":function($event){_vm.date ? _vm.getcal() : _vm.getcal()}},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,2402931442),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"min":_vm.minmonth,"max":new Date().toISOString().substr(0, 10),"color":"#f4813f","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":[function($event){return _vm.get_daily_present_reports()},function($event){return _vm.$refs.dialog.save(_vm.date)}]}},[_vm._v(" OK ")])],1)],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.name == 'xs')?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-text-field',{staticClass:"mt-6 mr-2",attrs:{"label":"Search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.name == 'xs')?_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[(_vm.$vuetify.breakpoint.name != 'xs')?_c('v-spacer'):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({on:{"click":function($event){return _vm.view_geo_for_the_day()}}},on),[_c('v-btn',{staticClass:"gradient-bg white--text mr-2",attrs:{"small":""}},[_c('v-icon',[_vm._v("mdi-map")])],1)],1)]}}],null,false,1181159147)},[_c('span',{staticClass:"white--text"},[_vm._v("View Geo Map for "+_vm._s(_vm.date))])])],1)],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"height":_vm.height,"headers":_vm.PresentData,"items":_vm.PresentItems,"dense":"","search":_vm.search,"loading":_vm.isLoading,"items-per-page":100,"fixed-header":_vm.fixed,"footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v("No prezence / swipe data available ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.first_swipe",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_datefirstSwipe(item).split(',')[1])}})]}},{key:"item.last_swipe",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_datelastSwipe(item).split(',')[1])}})]}},{key:"item.Actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(item)?_c('v-icon',_vm._g({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.view_swipes_for_the_day(item)}}},on),[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("View all Swipes")])])]}},{key:"item.user_name",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(_vm._s(item.user_name))])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.user_name))])])]}},{key:"item.location_name",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.location_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.location_name))])])]}}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('ViewMemberSwipes',{attrs:{"userDetailsObj":_vm.userDetailsObj,"viewMemberSwipesDialog":_vm.viewMemberSwipesDialog,"toggle":_vm.toggle,"date":_vm.date},on:{"close_view_member_details":function($event){_vm.viewMemberSwipesDialog = false}}}),_c('DailyPresentGeo',{ref:"DailyPresentGeo",attrs:{"dailyPresentGeoDialog":_vm.dailyPresentGeoDialog,"swipeData":_vm.geoSwipData},on:{"close_dailyPresentGeoDialog":function($event){_vm.dailyPresentGeoDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }