<template>
  <v-dialog fullscreen persistent v-model="dailyPresentGeoDialog">
    <v-card>
      <v-toolbar dense dark class="gradient-bg2">
        <v-toolbar-items class="mt-4">
          Map view for - {{ `${callmethoddate(date)}` }}
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-btn class="" text dark @click="close_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <GmapMap
        :center="
          swipeData[0] != undefined ? swipeData[0].position : defaultCenter
        "
        :zoom="5"
        map-type-id="terrain"
        style="width: 100%; height: 700px"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in swipeData"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :title="m.member_name + ' swipped at: ' + m.swipe_location"
          @click="center = m.position"
          :icon="{ url: m.marker }"
        />
      </GmapMap>
    </v-card>
  </v-dialog>
</template>
 
<script>
export default {
  props: {
    dailyPresentGeoDialog: Boolean,
    swipeData: Array,
  },
  data: () => ({
    dialog: false,
    date: "",
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
  }),
  methods: {
    close_dialog() {
      this.$emit("close_dailyPresentGeoDialog");
    },
    callmethoddate(displaydate) {
      let a = new Date(displaydate * 1000).toLocaleDateString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a;
    },
    getMethods(rec) {
      this.date = rec.date;
    },
  },
};
</script>

<style>
</style>